<script setup>

const { t } = useI18n();

</script>

<template>
    <div>
        <div class="w-full max-w-[1200px] mx-auto mb-16 px-12 pt-16">
            <div class="flex flex-row items-center justify-start pb-5 border-b">
                <img src="/layout/images/logo-footer.svg" class="w-[135px]">
                <div class="text-sm text-black text-opacity-50 w-[305px] ml-10">
                    {{ t('pages.home.tagline') }}
                </div>
                <div class="flex-1" />
                <div class="flex flex-row gap-8">
                    <a href="https://www.instagram.com/goexpond" target="_blank"><img src="/layout/brands/instagram.svg" alt="Instagram" width="24" height="24"></a>
                    <a href="https://www.facebook.com/GoExpond" target="_blank"><img src="/layout/brands/facebook.svg" alt="Facebook" width="24" height="24"></a>
                    <a href="mailto:info@goexpond.com" target="_blank"><img src="/layout/brands/email.svg" alt="Email" width="24" height="24"></a>
                </div>
            </div>
            <div class="flex flex-row items-center mt-7">
                <div class="text-[#202831]">
                    Copyright &copy; {{ (new Date()).getFullYear() }}. Go Expond.
                </div>
                <div class="flex-1" />
                <ul class="text-[#202831] flex flex-row items-center justify-center gap-8">
                    <li>
                        <a href="https://goexpond.com" target="_blank">About</a>
                    </li>
                    <li>
                        <a href="https://goexpond.com/health-professionals/" target="_blank">Work with us</a>
                    </li>
                    <li>
                        <a href="https://goexpond.com/contact/" target="_blank">Contact</a>
                    </li>
                    <li>
                        <a href="https://goexpond.com/privacy-policy/" target="_blank">Privacy Policy</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped></style>
